'use client';

import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { toClassName } from 'helpers/format';
const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>>(({
  className,
  ...props
}, ref) => <AvatarPrimitive.Root ref={ref} className={toClassName('relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full', className)} {...props} />);
Avatar.displayName = AvatarPrimitive.Root.displayName;
const AvatarImage = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Image>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>>(({
  className,
  ...props
}, ref) => <AvatarPrimitive.Image ref={ref} className={toClassName('aspect-square h-full w-full', className)} {...props} />);
AvatarImage.displayName = AvatarPrimitive.Image.displayName;
const AvatarFallback = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Fallback>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>>(({
  className,
  ...props
}, ref) => <AvatarPrimitive.Fallback ref={ref} className={toClassName('flex h-full w-full items-center justify-center rounded-full bg-neutral-100 dark:bg-neutral-800', className)} {...props} />);
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;
export { Avatar, AvatarImage, AvatarFallback };