import React from 'react';
import { handleGenerateInitialsFromName } from 'helpers/format';
import { handleUseStrapiImageUrl } from 'helpers/strapi';
import { APIResponseData } from 'types/models';
import { TNextServerComponent } from 'types/next';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn-ui/Avatar';
import { styles } from '.';
type TProps = {
  review: APIResponseData<'api::review.review'>;
};
const ReviewItem: TNextServerComponent<TProps> = async props => {
  const {
    review
  } = props;
  const handleGenerateDescription = (_?: string, location?: string) => {
    const segments = [];

    // if (date) {
    //   segments.push(date);
    // }

    if (location) {
      segments.push(location);
    }
    return segments.join(', ');
  };
  return <div className={styles.container} data-sentry-component="ReviewItem" data-sentry-source-file="ReviewItem.tsx">
      <div className={styles.body}>
        <p className="!text-sm !leading-relaxed !font-light">
          {review.attributes.content}
        </p>
      </div>
      <div className={styles.foot.container}>
        <Avatar style={{
        width: '45px',
        height: '45px'
      }} data-sentry-element="Avatar" data-sentry-source-file="ReviewItem.tsx">
          <AvatarImage src={handleUseStrapiImageUrl(review.attributes?.image?.data?.attributes?.url ?? '')} alt={review.attributes.image?.data?.attributes?.alternativeText} data-sentry-element="AvatarImage" data-sentry-source-file="ReviewItem.tsx" />
          <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="ReviewItem.tsx">
            {handleGenerateInitialsFromName(review.attributes.author)}
          </AvatarFallback>
        </Avatar>
        <div className={styles.foot.body}>
          <p className={styles.foot.title}>{review.attributes.author}</p>
          <p className={styles.foot.description}>
            {handleGenerateDescription(review.attributes?.date ? new Date(review.attributes?.date).toLocaleDateString('nl') : undefined, review.attributes?.location)}
          </p>
        </div>
      </div>
    </div>;
};
export default ReviewItem;